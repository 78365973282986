.loginForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      padding: 6px;
    }
    p {
      margin-bottom: 0px;
    }
  }
}
