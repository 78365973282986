@import '/src/styles/base.scss';

.notificationPrompt {
  padding: 8px 0;
  background-color: $color-light-gray;
  color: white;
  z-index: 9999;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  display: flex;
  justify-content: center;
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    span {
      font-weight: bold;
    }
    &__text {
      font-size: 1.3rem;
      text-align: center;
      margin-right: 20px;
      white-space: pre-line;
    }
    &__buttons {
      display: flex;
    }
  }
  &__info {
    border-left: 20px solid $color-light-blue;
  }
  &__success {
    border-left: 20px solid $color-success;
  }
  &__error {
    border-left: 20px solid $color-error;
  }
  &__warning {
    border-left: 20px solid $color-gold;
  }
}
