.logic-node {
  position: relative;
  height: 8rem;
  width: 5rem;
  line-height: 5rem;
  text-align: center;
  font-size: 12px;
  color: #8a8b8c;
}
.logic-node:after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  height: 5rem;
  width: 5rem;
  border: 1px solid #3594b6;
  transform: rotateX(45deg) rotateZ(45deg);
}
