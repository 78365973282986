@import '/src/styles/base.scss';

div.header {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: $color-dark-teal;
}

div.clickable {
  cursor: pointer;
}

.section {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  &__select {
    margin-left: 10px;
  }
}

.topHeader {
  margin-bottom: 10px;
}

.expandMenu {
  cursor: pointer;
  text-decoration: underline;
}

.expandableRow {
  display: 'flex';
  margin-top: 10px;
  margin-bottom: 10px;
}

.personalData {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  &__select {
    margin-left: 10px;
  }
}

.settingsTable {
  .content__row {
    margin-top: 20px;
    margin-bottom: 25px;
  }
}

.content {
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 9px;
    margin-top: 20px;
    margin-bottom: 25px;
    &__title {
      margin-right: 10px;
      font-weight: bold;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .buttonColor {
      margin-bottom: 0px;
    }
  }
}

.settingsCard {
  display: flex;
  justify-content: space-around;
  &__content {
    &__deviceTable {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    @include xs {
      padding: 10px;
    }
    @include sm {
      display: grid;
      column-gap: 20px;
      row-gap: 20px;
      grid-template-columns: minmax(min-content, 500px) minmax(
          min-content,
          500px
        );
    }
    @include lg {
      column-gap: 100px;
      row-gap: 20px;
    }
  }
}

.buttonColor {
  text-transform: none;
  background-color: $color-white;
  color: $color-light-blue;
  border: 1px solid $color-light-blue;
  width: 118px;
  margin: 20px 0px;
  &:hover {
    background-color: $color-light-blue;
    color: $color-white;
    border: 1px solid $color-light-blue;
  }
}

.passwordLine {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 9px;
  row-gap: 10px;
}

.changePassword {
  display: flex;
  flex-direction: column;
  .buttonColor {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
    text-transform: none;
    background-color: $color-white;
    color: $color-light-blue;
    border: 1px solid $color-light-blue;
    &:hover {
      background-color: $color-light-blue;
      color: $color-white;
      border: 1px solid $color-light-blue;
    }
  }
}

.avDeviceTableRows {
  display: flex;
  justify-content: center;
  align-items: center;
  .buttonColor {
    margin-top: 15px;
    margin-bottom: 0px;
    text-transform: none;
    background-color: $color-white;
    color: $color-light-blue;
    border: 1px solid $color-light-blue;
    &:hover {
      background-color: $color-light-blue;
      color: $color-white;
      border: 1px solid $color-light-blue;
    }
  }
}

.changeTOTP {
  .buttonColor {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
    text-transform: none;
    background-color: $color-white;
    color: $color-light-blue;
    border: 1px solid $color-light-blue;
    &:hover {
      background-color: $color-light-blue;
      color: $color-white;
      border: 1px solid $color-light-blue;
    }
  }
  &__content {
    display: flex;
    flex-direction: row;
  }
  &__line {
    margin-top: 20px;
  }
  &__sameLine {
    display: flex;
    div {
      margin-right: 10px;
    }
  }
}

.textField {
  background-color: white;
  input {
    padding: 5px 10px;
  }
}

.numberField {
  width: 220px !important;
}

.AVDeviceTable {
  .buttonColor {
    margin: 0px 20px;
    text-transform: none;
    background-color: $color-white;
    color: $color-light-blue;
    border: 1px solid $color-light-blue;
    &:hover {
      background-color: $color-light-blue;
      color: $color-white;
      border: 1px solid $color-light-blue;
    }
  }
}

.userSetting {
  display: flex;
  flex-direction: column;
  padding-left: 9px;
  &__line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .buttonColor {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 0px;
    text-transform: none;
    background-color: $color-white;
    color: $color-light-blue;
    border: 1px solid $color-light-blue;
    &:hover {
      background-color: $color-light-blue;
      color: $color-white;
      border: 1px solid $color-light-blue;
    }
  }
}

.changeMFA {
  .buttonColor {
    margin-top: 5px;
    width: 100%;
    margin-bottom: 0px;
    text-transform: none;
    background-color: $color-white;
    color: $color-light-blue;
    border: 1px solid $color-light-blue;
    &:hover {
      background-color: $color-light-blue;
      color: $color-white;
      border: 1px solid $color-light-blue;
    }
  }
}

.verificationLine {
  margin-top: 10px;
  margin-bottom: 30px;
  span {
    color: $color-light-blue;
  }
}
