@import '/src/styles/base.scss';

.brandedButton {
  .buttonColor {
    text-transform: none;
    background-color: $color-white;
    color: $color-light-blue;
    border: 1px solid $color-light-blue;
    width: 100%;
    &:hover {
      background-color: $color-light-blue;
      color: $color-white;
      border: 1px solid $color-light-blue;
    }
    &.Mui-disabled {
      background-color: $color-white;
      border: 1px solid $color-white;
    }
  }
}
