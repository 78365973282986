:root {
  --amplify-primary-color: #2a81a7;
  --amplify-secondary-color: #193139;
  --link-color: white;
  --subtitle-color: white;
  --amplify-grey: black;
  --box-shadow: 0px 0px;
}

amplify-authenticator {
  --box-shadow: 0px 0px;
}

amplify-button {
  background-color: grey;
  --background-color: grey !important;
}
