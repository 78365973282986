@import '/src/styles/base.scss';

.videoContainer {
  position: relative;

  &__videoIcon {
    color: rgb(103, 101, 101);
    position: absolute;
    top: 0;
    @include xs {
      font-size: 15px;
    }
    @include between-sm-and-md {
      font-size: 20px;
    }
  }
}
