.requirements {
  display: flex;
  flex-direction: column;
}

.requirement {
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    margin: 0px;
  }
  span {
    padding: 6px;
  }
}
