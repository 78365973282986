@import '/src/styles/base.scss';

.incomingCall {
  padding: 20px;
  width: 450px;
  height: 250px;
  background-color: white;
  color: $color-black;
  z-index: 9999;
  border-left: 10px solid $color-light-blue;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  display: flex;
  justify-content: center;
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      font-weight: bold;
    }
    &__text {
      margin-bottom: 30px;
      font-size: 1.4rem;
      text-align: center;
    }
    &__buttons {
      display: flex;
    }
    .acceptCall {
      width: 100px;
      text-transform: capitalize;
      background-color: $color-success;
      color: white;
      margin-right: 30px;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      &:hover {
        background-color: white;
        color: $color-success;
        border: 1px solid $color-success;
      }
    }
    .rejectCall {
      width: 100px;
      text-transform: capitalize;
      background-color: $color-error;
      color: white;
      margin-top: 10px;
      margin-left: 5px;
      display: flex;
      flex-direction: row;
      &:hover {
        background-color: white;
        color: $color-error;
        border: 1px solid $color-error;
      }
    }
  }
}
