// Smart phones
$screen-xs-min: 0px;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 600px;

// Between large smartphones and small tablets
$screen-between-sm-and-md-min: 790px;

// Small tablets (portrait view)
$screen-md-min: 1002px;

// Tablets and small desktops
$screen-lg-min: 1200px;

// Large tablets and desktops
$screen-xl-min: 1536px;

@mixin xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin between-sm-and-md {
  @media (min-width: #{$screen-between-sm-and-md-min}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}
