@import '/src/styles/base.scss';

@media only screen and (max-width: 1026) {
  .SideBar-root-1 .MuiToolbar-gutters {
    padding-left: 0px;
    padding-right: 0px;
  }
  .SideBar-root-1 .MuiCardContent-root {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.drawer {
  :global(.MuiDrawer-paper) {
    background-color: $color-light-gray;
    overflow: visible;
    @include xs {
      width: 100%;
    }
    @include md {
      width: $drawer-width;
    }
  }
}

.drawerContainerLeft {
  overflow-y: auto;
  padding-bottom: 50px;

  .sideBarRow {
    background-color: $color-dark-gray;
    border-top-left-radius: 80px 80px;
    border-bottom-left-radius: 80px 80px;
    margin-bottom: 2px;
    height: 50px;
    width: 282px;
  }

  .sideBarRow:hover {
    background-color: $color-white;
    border-top-left-radius: 80px 80px;
    border-bottom-left-radius: 80px 80px;
    margin-bottom: 2px;
    height: 50px;
    color: $color-dark-teal;
    right: 5px;
    width: 287px;
    margin-right: -5px;

    > span {
      border-right: 5px solid $color-light-blue;
    }

    .menuRow {
      color: $color-dark-teal;
    }
  }

  .sideBarRowActive {
    background-color: $color-white;
    border-top-left-radius: 80px 80px;
    border-bottom-left-radius: 80px 80px;
    margin-bottom: 2px;
    height: 50px;
    background-color: $color-white;
    color: $color-dark-teal;
    right: 5px;
    width: 287px;
    margin-right: -5px;
    > span {
      border-right: 5px solid $color-light-blue;
    }
  }

  .sideBarRowActive:hover {
    background-color: $color-white;
  }

  .active {
    color: $color-dark-teal;
    background-color: $color-white;
    span {
      font-weight: bold;
    }
  }

  .active:hover {
    background-color: $color-white;
    color: $color-dark-teal;
  }
  ul {
    display: flex;
    flex-direction: column;
    @include xs {
      align-items: center;
    }
    @include md {
      align-items: flex-end;
    }

    padding-right: 0px;
  }
}

.drawerContainerRight {
  .sideBarRow {
    background-color: $color-dark-gray;
    border-top-right-radius: 80px 80px;
    border-bottom-right-radius: 80px 80px;
    margin-bottom: 2px;
    height: 50px;
    width: 282px;
  }

  .sideBarRow:hover {
    background-color: $color-white;
    border-top-right-radius: 80px 80px;
    border-bottom-right-radius: 80px 80px;
    margin-bottom: 2px;
    height: 50px;
    color: $color-dark-teal;
    left: 5px;
    width: 287px;
    > span {
      border-left: 5px solid $color-light-blue;
    }
    .menuRow {
      color: $color-dark-teal;
    }
  }

  .sideBarRowActive {
    background-color: $color-white;
    border-top-right-radius: 80px 80px;
    border-bottom-right-radius: 80px 80px;
    margin-bottom: 2px;
    height: 50px;
    background-color: $color-white;
    color: $color-dark-teal;
    right: 5px;
    width: 287px;
    > span {
      border-left: 5px solid $color-light-blue;
    }
  }

  .sideBarRowActive:hover {
    background-color: $color-white;
  }

  .active {
    color: $color-dark-teal;
    background-color: $color-white;
    span {
      font-weight: bold;
    }
  }
  .active:hover {
    background-color: $color-white;
    color: $color-dark-teal;
  }
  ul {
    display: flex;
    flex-direction: column;
    @include xs {
      align-items: center;
    }
    @include md {
      align-items: flex-end;
    }

    padding-right: 0px;
  }
}

.topContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.drawerHeaderBold {
  color: $color-white;
  font-size: 17.5px;
}

.active {
  color: $color-dark-teal;
}

.drawerHeader {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
}

.onlineDot {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.offlineDot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

.menuRow {
  span {
    font-weight: bold;
  }
}

.selectPortal {
  display: flex;
  justify-content: center;
}

.closeButton {
  border: none;
  border-radius: 50%;
  background-color: $color-white;
  opacity: 0.7;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 10px;
  top: 10px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    width: 100%;
    height: 100%;
    fill: red;
  }
}

.sidebar-enter {
  transform: translateX(-100%);
}

.sidebar-enter-active {
  transform: translateX(0%);
  transition: transform 500ms ease-in-out;
}

.sidebar-exit {
  transform: translateX(0%);
}

.sidebar-exit-active {
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out;
}

.unreadMessages {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-end;
  width: 100px;
}

.unreadMessagesCount {
  font-size: 15px !important;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: $color-dark-teal;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
